import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAuth from '../reducers/auth.reducer';
import { CallState } from '@app/store/utils';

export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
  fromAuth.featureKey
);

export const selectUser = createSelector(
  selectAuthState,
  (state) => state.user
);

export const selectLoginCallState = createSelector(
  selectAuthState,
  (state) => state.loginCallState
);

export const selectApiError = createSelector(
  selectAuthState,
  (state) => state.apiError
);

export const selectChangePasswordCallState = createSelector(
  selectAuthState,
  (state): CallState => state.changePasswordCallState
);

export const selectChangePasswordApiError = createSelector(
  selectAuthState,
  (state) =>
    state.changePasswordCallState === CallState.Error ? state.apiError : null
);

export const selectPending = createSelector(
  selectAuthState,
  (state) => state.pending
);

export const selectUrlForRedirectionAfterLogin = createSelector(
  selectAuthState,
  (state) => state.urlForRedirectionAfterLogin
);
