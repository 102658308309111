import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Store, Action } from '@ngrx/store';
import { catchError, map, Observable, of, switchMap, take } from 'rxjs';

import { ProfileDocumentService } from '@eros-api/services';
import { OpsAccountsSelectors } from '@app/store';
import { OpsAccountModel, ProfileDocumentModel } from '@eros-api/models';

import { IDocumentsState } from '../models/pending-documents-future.model';
import { IPendingDocumentViewModel } from '../../models/pending-document-view.model';
import { pendingDocumentsPageActions } from '..';
import { AuthService } from '@app/libs/auth/data-access';

@Injectable()
export class PendingDocumentsPageEffects implements OnInitEffects {
  constructor(
    private store: Store<IDocumentsState>,
    private actions$: Actions,
    private router: Router,
    private profileDocumentService: ProfileDocumentService,
    private authService: AuthService
  ) {
    this.router.createUrlTree([this.router.url]);
  }

  ngrxOnInitEffects(): Action {
    if (this.authService.getAccessToken()) {
      this.store.dispatch(pendingDocumentsPageActions.loadPendingDocuments());
    }

    return { type: '' };
  }

  loadPendingDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pendingDocumentsPageActions.loadPendingDocuments),
      switchMap(() =>
        this.profileDocumentService.findPending().pipe(
          switchMap((documents) => this.enhanceDocuments(documents)),
          map((documents) => {
            return pendingDocumentsPageActions.loadPendingDocumentsSuccess({
              documents
            });
          }),
          catchError((error) =>
            of(
              pendingDocumentsPageActions.loadPendingDocumentsFailure({ error })
            )
          )
        )
      )
    )
  );

  private enhanceDocuments(
    documents: ProfileDocumentModel[]
  ): Observable<IPendingDocumentViewModel[]> {
    const opsAccountEntityMap$ = this.store
      .select(OpsAccountsSelectors.selectEntities)
      .pipe(take(1));

    return opsAccountEntityMap$.pipe(
      map((opsAccountEntityMap: Dictionary<OpsAccountModel>) => {
        documents.forEach((doc: IPendingDocumentViewModel) => {
          if (doc.accountId !== doc.createdBy) {
            doc.createdByAccount = opsAccountEntityMap[doc.createdBy];
          }
        });

        return documents;
      })
    );
  }
}
