import { createFeatureSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import type { RouterReducerState } from '@ngrx/router-store';

export const selectRouterState = createFeatureSelector<RouterReducerState<any>>(
  fromRouter.DEFAULT_ROUTER_FEATURENAME
);

export const {
  selectQueryParams,
  selectQueryParam,
  selectCurrentRoute,
  selectRouteData,
  selectRouteParam,
  selectRouteParams,
  selectUrl,
  selectFragment
} = fromRouter.getRouterSelectors(selectRouterState);
