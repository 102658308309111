import { createFeature, createReducer, on } from '@ngrx/store';

import { createDomainFeatureKey } from '@app/store/utils';
import { IAccountFraudState } from '@app/store/models/fraud.model';

import * as fraudActions from '../actions/fraud.actions';

export const fraudFeatureKey = createDomainFeatureKey('fraud');

export const fraudInitialState: IAccountFraudState = {
  isFraudDataLoading: null,
  detected: {
    accounts: [],
    revisions: [],
    documents: [],
    byIp: [],
    byCookie: []
  },
  accountsMap: null,
  isFound: false,
  isCleaningIpAndCookies: false
};

export const fraudFeature = createFeature({
  name: 'fraud',
  reducer: createReducer(
    fraudInitialState,
    on(fraudActions.findMatchingByIpAccountIdCookie, (state) => ({
      ...state,
      isFraudDataLoading: true
    })),
    on(
      fraudActions.findMatchingByIpAccountIdCookieSuccess,
      (state, { detected, accountsMap, isFound }) => ({
        ...state,
        isFraudDataLoading: false,
        detected,
        accountsMap,
        isFound
      })
    ),
    on(fraudActions.cleanupIpCookieConnections, (state) => ({
      ...state,
      isCleaningIpAndCookies: true
    })),
    on(fraudActions.cleanupIpCookieConnectionsSuccess, (state) => ({
      ...state,
      detected: {
        ...state.detected,
        byIp: [],
        byCookie: []
      },
      isCleaningIpAndCookies: false
    })),
    on(fraudActions.resetFraudStore, () => fraudInitialState)
  )
});
