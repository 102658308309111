import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromOpsAccounts from '../reducers/ops-accounts.reducer';
import { OpsAccountModel } from '@eros-api/models';

export const selectOpsAccountsState =
  createFeatureSelector<fromOpsAccounts.OpsAccountsState>(
    fromOpsAccounts.featureKey
  );

export const { selectEntities, selectAll } =
  fromOpsAccounts.entityAdapter.getSelectors(selectOpsAccountsState);

export const selectSortedAccounts = createSelector(selectAll, (accounts) =>
  accounts.sort((a, b) => {
    if (
      a.formatReferenceName?.toLowerCase() <
      b.formatReferenceName?.toLowerCase()
    ) {
      return -1;
    }
    if (
      a.formatReferenceName?.toLowerCase() >
      b.formatReferenceName?.toLowerCase()
    ) {
      return 1;
    }
    return 0;
  })
);

export const selectCallState = createSelector(
  selectOpsAccountsState,
  (state) => state.callState
);

export const selectEntityById = (id: OpsAccountModel['id']) =>
  createSelector(selectEntities, (entityMap) => entityMap[id] || null);
