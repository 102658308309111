import { Injectable } from '@angular/core';
import { catchError, mergeMap, of, switchMap } from 'rxjs';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { AccountService } from '@eros-api/services';
import { AuthService } from '@app/libs/auth/data-access';

import { opsAccountsActions } from '../actions';
import { OpsAccountModel } from '@eros-api/models';

@Injectable()
export class OpsAccountsEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private accountService: AccountService
  ) {}

  ngrxOnInitEffects(): Action {
    if (this.authService.getAccessToken()) {
      return opsAccountsActions.loadAccounts();
    }

    return { type: '' };
  }

  loadAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(opsAccountsActions.loadAccounts),
      switchMap(() =>
        this.accountService.getOpsAccounts().pipe(
          mergeMap((opsAccounts) => [
            opsAccountsActions.loadAccountsSuccess({
              opsAccounts: [
                ...opsAccounts,
                OpsAccountModel.fromJSON({
                  role: 'system',
                  username: 'system',
                  id: 1
                }),
                OpsAccountModel.fromJSON({
                  role: 'system',
                  username: 'revisor.bot',
                  id: 19
                })
              ]
            })
          ]),
          catchError(({ error }: HttpErrorResponse) =>
            of(opsAccountsActions.loadAccountsFailure({ error }))
          )
        )
      )
    )
  );
}
