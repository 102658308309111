import { InjectionToken } from '@angular/core';

import { IFlagWordsEntityState } from '@app/store/models/flag-words.models';
import { IAccountFraudState } from '@app/store/models/fraud.model';
import { ActionReducerMap } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

import * as fromAuth from './auth.reducer';
import * as fromFlagWords from './flag-words.reducer';
import * as fromFormData from './form-data.reducer';
import * as fromOpsAccounts from './ops-accounts.reducer';
import * as fromFraud from './fraud.reducer';
import * as fromDocuments from '@app/libs/document/features/pending-documents/state/pending-documents.feature';
import { IPendingDocumentsFeatureState } from '@app/libs/document/features/pending-documents/state/models/pending-documents-future.model';

export interface ICellarTigerState {
  router: fromRouter.RouterReducerState<any>;
  [fromOpsAccounts.featureKey]: fromOpsAccounts.OpsAccountsState;
  [fromAuth.featureKey]: fromAuth.AuthState;
  [fromFormData.featureKey]: fromFormData.FormDataState;
  [fromFlagWords.globalFlagWordsFeatureKey]: IFlagWordsEntityState;
  [fromFraud.fraudFeatureKey]: IAccountFraudState;
  [fromDocuments.pendingDocumentsFeatureKey]: IPendingDocumentsFeatureState;
}

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<ICellarTigerState>
>('ROOT_REDUCERS_TOKEN', {
  factory: () => ({
    router: fromRouter.routerReducer,
    [fromOpsAccounts.featureKey]: fromOpsAccounts.reducer,
    [fromAuth.featureKey]: fromAuth.reducer,
    [fromFormData.featureKey]: fromFormData.reducer,
    [fromFlagWords.globalFlagWordsFeatureKey]:
      fromFlagWords.globalFlagWordsFeature.reducer,
    [fromFraud.fraudFeatureKey]: fromFraud.fraudFeature.reducer,
    [fromDocuments.pendingDocumentsFeatureKey]:
      fromDocuments.pendingDocumentsFeature.reducer
  })
});
