import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { OpsAccountModel } from '@eros-api/models';

import { CallState, createDomainFeatureKey } from '../utils';
import { opsAccountsActions } from '../actions';

export const featureKey = createDomainFeatureKey('ops-accounts');

export interface OpsAccountsState extends EntityState<OpsAccountModel> {
  callState: CallState;
}

export const entityAdapter: EntityAdapter<OpsAccountModel> =
  createEntityAdapter<OpsAccountModel>({
    selectId: (entity) => entity.id,
    sortComparer: false
  });

const initialState: OpsAccountsState = entityAdapter.getInitialState({
  callState: CallState.Init
});

export const reducer = createReducer(
  initialState,
  on(opsAccountsActions.loadAccounts, (state) => ({
    ...state,
    callState: CallState.Loading
  })),
  on(opsAccountsActions.loadAccountsSuccess, (state, { opsAccounts }) =>
    entityAdapter.setAll(opsAccounts, { ...state, callState: CallState.Loaded })
  ),
  on(opsAccountsActions.loadAccountsSuccess, (state) => ({
    ...state,
    callState: CallState.Error
  }))
);
