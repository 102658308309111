import { AuthEffects } from './auth.effects';
import { OpsAccountsEffects } from './ops-accounts.effects';
import { FormDataEffects } from './form-data.effects';
import { PendingDocumentsPageEffects } from '@app/libs/document/features/pending-documents/state/effects/pending-documents.effects';

export const rootEffects = [
  AuthEffects,
  OpsAccountsEffects,
  FormDataEffects,
  PendingDocumentsPageEffects
];
