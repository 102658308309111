import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, filter, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@app/libs/auth/data-access';

import { authActions, formDataActions, opsAccountsActions } from '../actions';
import { pendingDocumentsPageActions } from '@app/libs/document/features/pending-documents/state';
import { AccountRoleEnum } from '@eros-api/models';
import { AccountService } from '@eros-api/services';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Toasty } from '@app/libs/shared/typings/toasty/toasty.enum';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private store: Store,
    private accountService: AccountService,
    private matSnackBar: MatSnackBar
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      switchMap(({ username, password }) =>
        this.authService.signIn(username, password).pipe(
          tap(({ resetPassword, id, token }) => {
            if (resetPassword) {
              this.store.dispatch(
                authActions.activateChangePassword({
                  accountId: id,
                  username,
                  token
                })
              );
            }
          }),
          filter(({ resetPassword }) => !resetPassword),
          tap(({ token, permissions, resetPassword }) => {
            this.authService.setAccessToken(token);
            this.authService.setPermissions(permissions);
          }),
          mergeMap(() => {
            if (this.authService.redirectUrl) {
              this.router.navigateByUrl(this.authService.redirectUrl);
            } else if (
              this.authService.getDecodedAccessToken().role ===
              AccountRoleEnum.Manager
            ) {
              this.router.navigateByUrl('/app/dashboard');
            } else {
              this.router.navigateByUrl('/app');
            }

            return [
              pendingDocumentsPageActions.loadPendingDocuments(),
              opsAccountsActions.loadAccounts(),
              authActions.loginSuccess({
                account: this.authService.getDecodedAccessToken()
              }),
              formDataActions.loadFormData()
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            const errorMessageCode =
              error.status === 403
                ? 'WRONG_USERNAME_OR_PASSWORD'
                : 'SOMETHING_WENT_WRONG';
            return of(
              authActions.loginFailure({ error: new Error(errorMessageCode) })
            );
          })
        )
      )
    )
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.changePassword),
      switchMap(({ accountId, reqBody, token }) =>
        this.accountService.updatePassword(accountId, reqBody, token).pipe(
          map(() => {
            this.matSnackBar.open(`Password changed`, '', {
              panelClass: Toasty.Success
            });
            return authActions.changePasswordSuccess();
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              authActions.changePasswordFailure({
                error: {
                  ...error,
                  message: error.error?.message ?? error.message
                }
              })
            )
          )
        )
      )
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.logout),
        tap(() => {
          this.authService.signOut();
          window.location.href = '/sign-in';
        })
      ),
    {
      dispatch: false
    }
  );
}
