import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

import { IFlagWordsEntityState } from '@app/store/models/flag-words.models';
import { FlagWordsModel } from '@eros-api/models';

import { globalFlagWordsActions } from '../actions';
import { createDomainFeatureKey } from '../utils';

export const globalFlagWordsFeatureKey = createDomainFeatureKey('flag-words');

export const entityAdapter: EntityAdapter<FlagWordsModel> =
  createEntityAdapter<FlagWordsModel>({
    selectId: (entity) => entity.id,
    sortComparer: false
  });

const initialState: IFlagWordsEntityState = entityAdapter.getInitialState({
  isFlagWordsLoading: false,
  isCreatingFlagWordLoading: false,
  isDeletingFlagWordLoading: false
});

export const globalFlagWordsFeature = createFeature({
  name: globalFlagWordsFeatureKey,
  reducer: createReducer(
    initialState,
    on(globalFlagWordsActions.loadFlagWords, (state) => ({
      ...state,
      isFlagWordsLoading: true
    })),
    on(globalFlagWordsActions.loadFlagWordsSuccess, (state, { flagWords }) =>
      entityAdapter.addMany(flagWords, {
        ...state,
        isFlagWordsLoading: false
      })
    ),
    on(globalFlagWordsActions.creatFlagWord, (state) => ({
      ...state,
      isCreatingFlagWordLoading: true
    })),
    on(globalFlagWordsActions.creatFlagWordSuccess, (state, { flagWord }) =>
      entityAdapter.addOne(flagWord, {
        ...state,
        isCreatingFlagWordLoading: false
      })
    ),
    on(globalFlagWordsActions.creatFlagWordFailure, (state) => ({
      ...state,
      isCreatingFlagWordLoading: false
    })),
    on(globalFlagWordsActions.deleteFlagWord, (state) => ({
      ...state,
      isDeletingFlagWordLoading: true
    })),
    on(globalFlagWordsActions.deleteFlagWordSuccess, (state, { id }) =>
      entityAdapter.removeOne(id, {
        ...state,
        isDeletingFlagWordLoading: false
      })
    )
  )
});
