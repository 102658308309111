import { createSelector } from '@ngrx/store';

import { IAccountFraudState } from '@app/store/models/fraud.model';
import { fraudFeatureKey } from '@app/store/reducers/fraud.reducer';
import {
  FlagWordType,
  IFindFlagWordsByTypeValuesReqModel,
  KeyToFlagWordType
} from '@eros-api/models';
import { FraudDetectionResult } from '@eros-api/models/IPMatchModel';

const selectFraudFeatureState = (state: IAccountFraudState) =>
  state[fraudFeatureKey];

export const selectIsFraudDataLoading = createSelector(
  selectFraudFeatureState,
  (state) => state.isFraudDataLoading
);

export const selectFraudData = createSelector(
  selectFraudFeatureState,
  (state) => ({
    detected: state.detected,
    markableFields: getMarkableFields(state.detected)
  })
);

export const selectAccountsMap = createSelector(
  selectFraudFeatureState,
  (state) => state.accountsMap
);

export const selectIsFound = createSelector(
  selectFraudFeatureState,
  (state) => state.isFound
);

export const getMarkableFields = (
  detected: FraudDetectionResult | null
): IFindFlagWordsByTypeValuesReqModel[] => {
  if (!detected) {
    return [];
  }

  const res: IFindFlagWordsByTypeValuesReqModel[] = [];
  let matchedTypes: string[] = [];

  for (const key in detected) {
    if (
      !['accounts', 'revisions', 'documents'].includes(key) ||
      detected[key].length === 0
    ) {
      continue;
    }

    if (key === 'accounts') {
      matchedTypes = ['account_full_name', 'email', 'phone_number'];
    }

    if (key === 'revisions') {
      matchedTypes = [
        'revision_full_name',
        'emails',
        'phone_numbers',
        'websites'
      ];
    }

    if (key === 'documents') {
      matchedTypes = ['document_full_name', 'id_number'];
    }

    const highlights: Array<
      { field: string; match: string[]; type: string }[]
    > = detected[key].map((acc) => acc.highlight);

    highlights.forEach((fields: any[]) => {
      fields
        .filter((highlight) => matchedTypes.includes(highlight.type))
        .forEach((highlight) => {
          highlight.match.forEach((matched) => {
            const probableFW = res.find(
              (item) => item.type === normalizeFWTypes(highlight.type)
            );
            const value = stripTags(matched);

            probableFW
              ? !probableFW.values.includes(value) &&
                probableFW.values.push(value)
              : res.push({
                  type: normalizeFWTypes(highlight.type),
                  values: [value]
                });
          });
        });
    });
  }

  return res;
};

export const stripTags = (match: string): string => {
  return match.replace(/<\/?[^>]+>/g, '');
};

export const normalizeFWTypes = (type: string): FlagWordType => {
  switch (type) {
    case KeyToFlagWordType.PhoneNumbers:
    case KeyToFlagWordType.PhoneNumber:
      return FlagWordType.BadPhoneNumber;
    case KeyToFlagWordType.Emails:
    case KeyToFlagWordType.Email:
      return FlagWordType.BadEmail;
    case KeyToFlagWordType.IdNumber:
      return FlagWordType.BadIdNumber;
    case KeyToFlagWordType.Websites:
      return FlagWordType.BadWebsite;
    case KeyToFlagWordType.AccountFullName:
    case KeyToFlagWordType.RevisionFullName:
      return FlagWordType.BadLegalName;
    case KeyToFlagWordType.DocumentFullName:
      return FlagWordType.BadIdName;
  }

  return FlagWordType.Empty;
};
