import { createReducer, on } from '@ngrx/store';

import { OpsAccountModel } from '@eros-api/models';

import { CallState, createDomainFeatureKey } from '../utils';
import { authActions } from '../actions';

export const featureKey = createDomainFeatureKey('auth');

export interface AuthState {
  user: OpsAccountModel | null;
  pending: boolean;
  loginCallState: CallState;
  changePasswordCallState: CallState;
  urlForRedirectionAfterLogin: string | null;
  apiError: Error | null; // todo: implement ResponseError
}

export const initialState: AuthState = {
  user: null,
  pending: false,
  urlForRedirectionAfterLogin: null,
  loginCallState: CallState.Init,
  changePasswordCallState: CallState.Init,
  apiError: null
};

export const reducer = createReducer(
  initialState,
  on(authActions.login, (state) => ({
    ...state,
    loginCallState: CallState.Processing,
    apiError: null
  })),
  on(authActions.loginSuccess, (state, { account }) => ({
    ...state,
    loginCallState: CallState.Processed,
    user: account,
    apiError: null
  })),
  on(authActions.loginFailure, (state, { error }) => ({
    ...state,
    loginCallState: CallState.Error,
    user: null,
    apiError: error
  })),
  on(authActions.activateChangePassword, (state) => ({
    ...state,
    changePasswordCallState: CallState.Init
  })),
  on(authActions.changePassword, (state) => ({
    ...state,
    changePasswordCallState: CallState.Loading
  })),
  on(authActions.changePasswordSuccess, (state) => ({
    ...state,
    changePasswordCallState: CallState.Loaded
  })),
  on(authActions.changePasswordFailure, (state, { error }) => ({
    ...state,
    changePasswordCallState: CallState.Error,
    user: null,
    apiError: error
  })),
  on(authActions.saveUrlForRedirectionAfterLogin, (state, { url }) => ({
    ...state,
    urlForRedirectionAfterLogin: url
  })),
  on(authActions.checkAccessToken, authActions.checkPermissions, (state) => ({
    ...state,
    pending: true
  }))
);
