import {
  IFlagWordsEntityState,
  ISelectFilteredFlagWords
} from '@app/store/models/flag-words.models';
import { globalFlagWordsFeatureKey } from '@app/store/reducers/flag-words.reducer';
import * as fromFlagWords from '@app/store/reducers/flag-words.reducer';
import { FlagWordType } from '@eros-api/models';
import { createSelector } from '@ngrx/store';

export const { selectAll } = fromFlagWords.entityAdapter.getSelectors();

const selectFlagWordsFeatureState = (state: IFlagWordsEntityState) =>
  state[globalFlagWordsFeatureKey];

export const selectFlagWords = createSelector(
  selectFlagWordsFeatureState,
  (state) => selectAll(state)
);

export const selectFlagWordsByTypes = (types: FlagWordType[]) =>
  createSelector(selectFlagWords, (state) =>
    state.filter((item) => types.includes(item.type))
  );

export const selectFlagWordsByTypesAndValue = (
  types: ISelectFilteredFlagWords[]
) =>
  createSelector(selectFlagWords, (state) => {
    return state.filter((stateItem) =>
      types.find(
        (item) =>
          item.type === stateItem.type &&
          stateItem.value?.toLowerCase() === item.value?.toLowerCase()
      )
    );
  });

export const selectFlagWordsByType = (type: FlagWordType) =>
  createSelector(selectFlagWords, (state) =>
    state.filter((item) => item.type === type)
  );

export const selectFlagWordById = (id: string) =>
  createSelector(selectFlagWords, (state) =>
    state.find((item) => item.id === id)
  );

export const selectIsFlagWordsLoading = createSelector(
  selectFlagWordsFeatureState,
  (state) => state.isFlagWordsLoading
);

export const selectIsCreatingFlagWordLoading = createSelector(
  selectFlagWordsFeatureState,
  (state) => state.isCreatingFlagWordLoading
);

export const selectIsDeletingFlagWordLoading = createSelector(
  selectFlagWordsFeatureState,
  (state) => state.isDeletingFlagWordLoading
);

export const selectIsProcessing = createSelector(
  selectIsDeletingFlagWordLoading,
  selectIsCreatingFlagWordLoading,
  (isDeleting, isCreating) => isDeleting || isCreating
);
